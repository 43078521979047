<template>
  <div class="container-fluid row">
    <div v-if="modalShowned" class="modal d-block mt-5" id="createQualifiers" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <template v-if="selectedRow.id">Edit</template>
              <template v-else>Add</template>
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalShowned = false"></button>
          </div>
          <div class="modal-body">
            <FormComponent :showSubmitButton="false" ref="form" :inputValues="selectedRow" v-model="selectedRow">
              <template v-slot:formInput="{ formData, inputName }">
                <div v-if="inputName === 'type'" class="mb-5 px-1 w-100 d-flex">
                  <strong><label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">Type</label></strong>
                  <select class="form-select" style="width: 150px;" v-model="selectedRow.type">
                    <option default :value="null">Select Type</option>
                    <option v-for="typeCmt in types" :key="typeCmt.id" :value="typeCmt.typeComment">
                      {{ typeCmt.typeComment }}
                    </option>
                  </select>
                </div>

                <div v-if="inputName == 'chargePer1000Pieces'" class="mb-5 px-1 w-100 d-flex">
                  <strong><label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">Charge per 1000 Pieces :</label></strong>
                  <div class="w-100 px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300">
                    {{ calculateTSSChargePerThousand }}</div>
                </div>

                <div v-if="inputName == 'payPer1000Pieces'" class="mb-5 px-1 w-100 d-flex">
                  <label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2"><strong>Pay per 1000 Pieces :</strong></label>
                  <div class="w-100 px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300">
                    {{ calculateInhousePayPerThousand }}</div>
                </div>

              </template>
            </FormComponent>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" id="cuBtn" data-bs-dismiss="modal" class="btn btn-primary" @click="addOrUpdateQualifier(selectedRow)">
              <template v-if="selectedRow.id">Update</template>
              <template v-else>Create</template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--YEARLY RATES MODEL-->
    <div v-if="ratesModalShown" class="modal d-block mt-5" id="inputRates" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5">Yearly Rates</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="ratesModalShown = false"
        ></button>
      </div>
      <div class="modal-body">
        <FormComponent :showSubmitButton="false" ref="form" :inputValues="rateRows" v-model="rateRows">
          <template v-slot:formInput="{ formData, inputName }">
            <div v-if="inputName === 'tssRates'" class="mb-5 px-1 w-100 d-flex">
              <label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">TSS Rates:</label>
              <input type="number" class="form-control" v-model="rateRows.tssRates" />
            </div>
            <div v-if="inputName === 'inHouse'" class="mb-5 px-1 w-100 d-flex">
              <label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">In-House Rates:</label>
              <input type="number" class="form-control" v-model="rateRows.inHouse" />
            </div>
          </template>
        </FormComponent>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          id="cuBtn"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          @click="addYearlyRates(rateRows)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

    

    <ABTable class="px-2" ref="qualifiersTable" :isLoading="isLoading" @onFetchRemoteData="fetchRemoteData" :table-data="rows" :page-title="pageTitle" 
    :router-url="routerUrl" :filters="filters" :columns="columns" :total-count="totalCount" :hasDetailsPage="true" @onDeleteRow="deleteRow">
      <template v-slot:createButton>
        <button id="export" class="upload-btn my-3 mx-1" @click="exportToCSV">Export CSV</button>
        <button id="yrRatebtn" type="button" class="my-3 mx-1" @click="ratesModalShown = true">Yearly Rates</button>
        <button id="newbtn" type="button" class="upload-btn my-3 mx-1" @click="showModal()">New</button>
      </template>
      <template v-slot:actions="{ row }">
        <div class="flex items-center justify-center w-full h-full">
          <button @click="showAlert(row.id)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="gg-trash"></i>
          </button>
          <button @click="showModal(row)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="bi bi-pencil"></i>
          </button>
        </div>
      </template>
    </ABTable>
  </div>
</template>

<script>
const defaultQualifierFields = {
  responsiblePerson: "",
  dateOfIssue: new Date().toISOString().substr(0, 10),
  partNumber: "",
  type: "",
  tssCode: "",
  multiplePerson: false,
  tssPiecesPerHour: 0,
  inHousePiecesPerhour: 0,
  chargePer1000Pieces: 0,
  payPer1000Pieces: 0,
  chargePer1000PiecesDelay: 0,
  payPer1000PiecesDelay: 0,
  chargePer1000PiecesExtraDelay: 0,
  payPer1000PiecesExtraDelay: 0,
};

const yearlyRates = {
  tssRates: 0,
  inHouse: 0,
};

import { useToast } from "vue-toast-notification";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { token } from "./token.js";
import ABTable from '../components/ABTable.vue'
import { useQualifierStore } from '@/stores/qualifiers.js'
import { useEmployeeStore } from '@/stores/employees.js'
import { useYearlyRatesStore } from '@/stores/yearlyRates.js'
import { useQualifiersTypeStore } from '@/stores/qualifiersType.js'
import FormComponent from '@/components/FormComponent.vue'
import { reactive, ref } from 'vue';

const $toast = useToast();

export default {
  components: {
    ABTable,
    FormComponent
  },
  computed: {},
  data() {
    return {
      rateRows: { ...yearlyRates },
      selectedRow: reactive({ ...defaultQualifierFields }),
      modalShowned: false,
      ratesModalShown: false,
      ids: [],
      rows: [],
      types: [],
      isLoading: false,
      yearlyRate: ref(null),
      searchQuery: "",
      page: 1,
      pageSize: 3,
      pageSizes: [3, 10, 20, 100],
      totalCount: 0,
      sortBy: "Id",
      sortDirection: "asc",
      pageTitle: 'Qualifiers',
      columns: [
        { label: "Id", field: "id", type: "number", width: "5%" },
        { label: "Responsible Person", field: "responsiblePerson", type: "string" },
        { label: "Date of Issue", field: "dateOfIssue", type: "DateTime" },
        { label: "Part Number", field: "partNumber", type: "string" },
        { label: "Type", field: "type", type: "string" },
        { label: "Method", field: "tssCode", type: "string" },
        { label: "TSSPieces/Hour", field: "tssPiecesPerHour", type: "number" },
        { label: "InHousePieces/Hour", field: "inHousePiecesPerhour", type: "number" },
        { label: "Charge/1000 Pieces", field: "chargePer1000Pieces", type: "number" },
        { label: "Pay/1000 Pieces ", field: "payPer1000Pieces" },
        { label: "Charge/1000 Pieces Delay", field: "chargePer1000PiecesDelay", type: "number" },
        { label: "Pay/1000 Pieces Delay", field: "payPer1000PiecesDelay", type: "number" },
        { label: "Charge/1000Pieces Extra Delay", field: "chargePer1000PiecesExtraDelay", type: "number" },
        { label: "Pay/1000 Pieces Extra Delay", field: "payPer1000PiecesExtraDelay", type: "number" },
      ],
    };
  },
  mounted: function () {
    this.fetchEmployee();
    this.getYearlyRate();
    this.fetchQualifierTypes();
  },
  computed: {
    calculateTSSChargePerThousand() {
      const tssPiecesPerHour = this.selectedRow.tssPiecesPerHour || 0; // Default to 0 if not provided
      const isBeltByTwo = this.selectedRow.multiplePerson || false;

      if (!tssPiecesPerHour || !this.yearlyRate || !this.yearlyRate.tssRates) {
        return "N/A"; // Return "N/A" if any required value is missing
      }

      const tssRatePerThousand = ((this.yearlyRate.tssRates * 1000) / tssPiecesPerHour).toFixed(2);
      return isBeltByTwo
        ? ((this.yearlyRate.tssRates * 2 * 1000) / tssPiecesPerHour).toFixed(2)
        : tssRatePerThousand;
    },
    calculateInhousePayPerThousand() {
      const inhousePiecesPerHour = this.selectedRow.inHousePiecesPerhour || 0; // Default to 0 if not provided
      const isBeltByTwo = this.selectedRow.multiplePerson || false;

      if (!inhousePiecesPerHour || !this.yearlyRate || !this.yearlyRate.inHouse) {
        return "N/A"; // Return "N/A" if any required value is missing
      }

      const inHouseRatePerThousand = ((this.yearlyRate.inHouse * 1000) / inhousePiecesPerHour).toFixed(2);
      return isBeltByTwo
        ? ((this.yearlyRate.inHouse * 2 * 1000) / inhousePiecesPerHour).toFixed(2)
        : inHouseRatePerThousand;
    },
},

  methods: {
    async fetchRemoteData(remoteParams) {
  this.isLoading = true;
  
  try {
    const qualifierStore = useQualifierStore();
    const dataResponse = await qualifierStore.searchQualifiers(
      remoteParams.page,
      remoteParams.pageSize,
      remoteParams.sortBy,
      remoteParams.sortDirection,
      remoteParams.searchQuery
    );

    // Check if dataResponse contains the expected properties
    if (dataResponse && dataResponse.items && dataResponse.totalCount !== undefined) {
      this.rows = dataResponse.items;
      this.totalCount = dataResponse.totalCount;
    } else {
      console.error("Unexpected response structure:", dataResponse);
      this.rows = [];
      this.totalCount = 0;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    this.rows = [];
    this.totalCount = 0;
  } finally {
    this.isLoading = false;
  }
},
    async fetchQualifierTypes() {
      const employeeStore = useQualifiersTypeStore()
      await employeeStore
        .fetchQualifierType()
        .then(async (res) => {
          if (res.ok) {
            this.types = await res.json();
          } else $toast.error("Error fetching Qualifier Types");
        })
        .catch((err) => {
          $toast.error("Error fetching Qualifier Types") + err;
        });
    },
    async getYearlyRate() {
      const yearlyRatesStore = useYearlyRatesStore();
      const response = await yearlyRatesStore.fetchRates();

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Yearly Rates:", data);

        // Assign the fetched rates to `rateRows` so they are reflected in the form
        this.rateRows.tssRates = data.tssRates || 0;
        this.rateRows.inHouse = data.inHouse || 0;

        // Also update `yearlyRate` if necessary for computed fields
        this.yearlyRate = data;
      } else {
        const error = await response.text();
        console.error("Error fetching yearly rates:", error);
        $toast.error("Failed to fetch yearly rates.");
      }
    },

    showModal(row) {
      if (row) {
        // Edit existing row
        this.selectedRow = { ...row };
      } else {
        // Create new entry
        this.selectedRow = { ...defaultQualifierFields };
      }
      this.modalShowned = true;
    },
    async addOrUpdateQualifier(formData) {
      const finalFormData = { 
        ...this.selectedRow, 
        ...formData,
        chargePer1000Pieces: this.calculateTSSChargePerThousand ? parseFloat(this.calculateTSSChargePerThousand) : null,
        payPer1000Pieces: this.calculateInhousePayPerThousand ? parseFloat(this.calculateInhousePayPerThousand) : null,
        entity: this.selectedRow.entity || '' // Ensure 'entity' field is included if required
      };

      const qualifierStore = useQualifierStore();

      let response;
      if (this.selectedRow.id) {
        response = await qualifierStore.updateQualifier(finalFormData);
      } else {
        response = await qualifierStore.createQualifier(finalFormData);
      }

      if (response.ok) {
        $toast.success(this.selectedRow.id ? `Edited` : `Created`);
        this.modalShowned = false; // Close the modal upon success
        this.$refs.qualifiersTable.reload(); // Reload the table to reflect changes
      } else {
        const err = await response.json();
        $toast.error(this.selectedRow.id ? `Error editing` : `Error creating`);
        console.error(err.errors); // Log error for debugging if needed
      }
    },
    async addYearlyRates(formData) {
        const finalFormData = {
          tssRates: this.rateRows.tssRates,
          inHouse: this.rateRows.inHouse,
        };

        const yearlyRatesStore = useYearlyRatesStore();
        const response = await yearlyRatesStore.createYearlyRate(finalFormData);

        if (response.ok) {
          $toast.success("Yearly rates updated successfully.");
          this.ratesModalShown = false;
          this.getYearlyRate(); // Refresh the rates
        } else {
          const error = await response.text();
          console.error("Error updating yearly rates:", error);
          $toast.error("Failed to update yearly rates.");
        }
    },
    async fetchEmployee() {
      const employeeStore = useEmployeeStore()
      await employeeStore
        .fetchEmployees()
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else $toast.error("Error fetching Employees");
        })
        .then((data) => (this.users = data))
        .catch((err) => {
          $toast.error("Error fetching Employees") + err;
        });
    },
    showAlert(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.remove(id);
          }
        });
    },
    async remove(id) {
      const qualifierStore = useQualifierStore()
      const dataResponse = await qualifierStore
        .deleteQualifier(id)
      this.$refs.qualifiersTable.reload()
    },
    exportToCSV() {
      const csvHeader = this.columns.map((column) => column.label).join(",");
      const csvData = this.rows.map((row) =>
        this.columns.map((column) => row[column.field]).join(",")
      );
      const csvContent = [csvHeader, ...csvData].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "qualifiers.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style>
.upload-btn {
  float: right;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

#cuBtn {
  background-color: #008cba;
  color: white;
}

#newbtn {
  float: right;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button .upload-btn:hover {
  background-color: #388e3c;
}

#yrRatebtn {
  float: right;
  background-color: hsl(246, 93%, 45%);
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

#export {
  float: right;
  background-color: hsl(9, 90%, 51%);
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}
</style>
